import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Core/Seo";
import { graphql } from "gatsby";
import ContactBlock from "../components/Contact/ContactBlock";

const ContactUsPage = ({data}) => {
    const page = data.page;
    const header = page.header;
    const title = page.title.raw;
    const content = page.content.raw;
    const link = page.link;
    const backgrounds = page.backgrounds;

    return (
    <Layout>
      <Seo title={page.metaTitle} metaTitle={page.metaTitle} metaDescription={page.metaDescription} path={'/contact'}/>
        <ContactBlock title={title} header={header} content={content} link={link} backgrounds={backgrounds}/>
    </Layout>
  )
}

export default ContactUsPage;

export const pageQuery = graphql`
    query ContactUsQuery {
        page: contentfulContactUs {
            metaTitle
            metaDescription
            title {
                raw
            }
            content {
                raw
            }
            link {
                ctaLabel
                ctaUrl
            }
            header {
                header
                position
            }
            backgrounds {
              backgroundDesktopDark {
                file {
                  url
                }
              }
              backgroundDesktopLight {
                file {
                  url
                }
              }
              backgroundMobileDark{
                file {
                  url
                }
              }
              backgroundMobileLight {
                file {
                  url
                }
              }
            }
        }
    }
`
