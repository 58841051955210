import React, { useState } from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import CheckedIconDark from "../../resources/images/ui/checked-dark.svg";
import CheckedIconLight from "../../resources/images/ui/checked-light.svg";

const Form = styled.form`
  margin-top: 56px;
  display: flex;
  flex-direction: column;

  @media(min-width: ${breakpoints.md}){
    margin-top: 56px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    margin-top: 130px;
  }
`;

const Textarea = styled.textarea`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.64px;
  ${fonts.RalewayRegular};
  padding: 15px 15px 14px 16px;
  min-height: 156px;
  resize: none;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.64px;
    ${fonts.RalewayRegular};
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
    transition: .1s;
  }
  
  &:focus {
    outline: none;
    
    &::placeholder {
      opacity: 0;
    }
    
    + label {
      opacity: 1;
    }
  }

  &:not(:placeholder-shown) + label {
    opacity: 1;
  }
`;

const Checkbox = styled.label`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  ${fonts.RalewayRegular}
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  margin-top: 16px;
  margin-bottom: 16px;
  display: inline-block;
  cursor: pointer;

  @media(min-width: ${breakpoints.md}){
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const CheckboxInput = styled.input`
  border: 1px solid ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
  cursor: pointer;
  border-radius: 0;
  outline: none;
  margin: 0 8px 0 0;
  display: inline-block;

  &:checked {
    background: url(${props => props.theme.isLight ? CheckedIconDark : CheckedIconLight}) no-repeat center center;
    background-size: 8px 8px;
  }
`;

const Button = styled.button`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  width: 140px;
  ${fonts.CabinRegular};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: .2s;
  height: 48px;
  color: ${props => props.theme.isLight ? colors.navy : colors.lightGrey};
  border: 1px solid ${props => props.theme.isLight ? colors.navy : colors.lightGrey};
  padding: 0 20px;
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};

  ::before, ::after {
    position: absolute;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
    z-index: -1;
    transition: .2s;
    content: '';
  }

  ::before {
    width: 10px;
    left: 10px;
    height: 1px;
    top: -1px;
  }

  ::after {
    width: 1px;
    height: ${props => props.large ? "15px" : "10px"};
    top: 20px;
    left: -1px;
  }

  :hover::before {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover::after {
    height: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover {
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover div {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  @media (min-width: ${breakpoints.md}) {
    height: 58px;
    width: 180px;

    ::before {
      width: 15px;
    }

    ::after {
      width: 1px;
      height: 15px;
      top: 20px;
    }
  }
`;

const After = styled.div`
  bottom: -1px;
  right: 20px;
  height: 1px;
  width: 15px;
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  transition: .2s;
  position: absolute;
  
  :hover {
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }
`

const InputContainer = styled.div`
  display: block;
  position: relative;
`;

const Input = styled.input`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey};
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.64px;
  ${fonts.RalewayRegular};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  padding: 15px 15px 14px 16px;
  width: 100%;
  box-sizing: border-box;

  &:not(:placeholder-shown) + label {
    opacity: 1;
  }

  &:focus {
    outline: none;
    
    ::placeholder {
      opacity: 0;
    }
  }
  
  &::placeholder {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.64px;
    ${fonts.RalewayRegular};
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  }
  
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 65px ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey} inset !important;
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.64px;
    ${fonts.RalewayRegular};
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.64px;
    ${fonts.RalewayRegular};
  }
`;

const Label = styled.label`
  display: block;
  opacity: 0;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  ${fonts.RalewayRegular};
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey};
  font-size: 12px;
  line-height: 12px;
  width: max-content;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  top: -5px;
  left: 14px;
  transition: .1s;
  
  ${Input}:focus ~ & {
    opacity: 1;
  }
`;

const Success = styled.div`
  ${fonts.RalewayRegular};
  
  h4 {
    font-size: 24px;
    line-height: 28px;
    padding: 8px 0;
  }
  
  p {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Error = styled.p`
  padding-bottom: 24px;
  color: ${colors.red};
  ${fonts.RalewayRegular};
`;

const ContactForm = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    privacy: null
  });

  const handleChange = (e) => {
    const newData = {...data}
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://tn4qhmgxpb.execute-api.eu-west-1.amazonaws.com/prod/api/contact", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      mode: 'cors',
      cache: 'no-cache',
      body: new URLSearchParams(data)
    })
      .then(() => {
          setSuccess(true);
        },
        (error) => {
          setError(error);
        }
      )
  }

  return (
    <Form method="POST" name="contact" id="contact-form" onSubmit={handleSubmit}>
      {success === true ?
        <Success>
          <h4>Thank you!</h4>
          <p>We will be in touch to arrange a call back within 24 hrs. Speak soon.</p>
        </Success>
        :
        <React.Fragment>
          <InputContainer>
            <Input value={data["name"]}
                   onChange={handleChange}
                   id="name"
                   name="name"
                   type="text"
                   aria-label="Name"
                   placeholder="Name"
                   required />
            <Label htmlFor="name" className="label-helper">Name</Label>
          </InputContainer>

          <InputContainer>
            <Input value={data["email"]}
                   onChange={handleChange}
                   id="email"
                   name="email"
                   type="email"
                   aria-label="Email"
                   placeholder="Email"
                   required />
            <Label htmlFor="email" className="label-helper">Email</Label>
          </InputContainer>

          <InputContainer>
            <Input value={data["phone"]}
                   onChange={handleChange}
                   name="phone"
                   id="phone"
                   type="text"
                   aria-label="Phone Number"
                   placeholder="Phone number" />
            <Label htmlFor="phone" className="label-helper">Phone number</Label>
          </InputContainer>
          <InputContainer>
            <Textarea value={data["message"]}
                      onChange={handleChange}
                      name="message"
                      id="message"
                      aria-label="Message"
                      placeholder="Message"
                      required/>
            <Label htmlFor="message">Message</Label>
          </InputContainer>
          <Checkbox>
            <CheckboxInput type="checkbox" id='privacy' name='privacy' required onChange={handleChange} />
            I agree to the use of cookies for google captcha as well as our Cookie Policy and Terms and Conditions
          </Checkbox>
          {(success === false && error) && <Error>Unable to send a message. Please try again.</Error>}
          <Button type="submit">
            Send
            <After/>
          </Button>
        </React.Fragment>
      }
    </Form>
  )
}

export default ContactForm;