import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ContentContainer from "../Core/ContentContainer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Title from "../Core/Title";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import { Col, Row } from "styled-bootstrap-grid";
import Button from "../Core/Button";
import ContactForm from "./ContactForm";
import ThemeContext from "../../services/themes/themeContext";
import { isBrowser } from "../../services/browser/browser";

const ContactContainer = styled.div`
  padding: 116px 0 100px;
  position: relative;

  @media(min-width: ${breakpoints.md}){
    padding: 133px 0 200px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    padding: 143px 0 320px;
  }
`;

const Header = styled.div`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 3.216px;
  text-transform: uppercase;
  ${fonts.CabinBold};
  margin-top: 56px;
  margin-bottom: 32px;
  text-align: center;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};

  b {
    ${fonts.Lagi};
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke: 1px ${colors.red};
  }
  
  @media(min-width: ${breakpoints.md}){
    text-align: left;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 2.8px;
    
    b {
      -webkit-text-stroke: 1.5px ${colors.red};
    }
  }
  
  @media(min-width: ${breakpoints.lg}){
    font-size: 72px;
    line-height: 87px;
    letter-spacing: 2.88px;
    margin-top: 130px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.576px;
  ${fonts.RalewayThin};
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  margin-bottom: 56px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.28px;
  }

  @media(min-width: ${breakpoints.lg}){
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1.28px;
  }
`;

const Background = styled.img`
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 80px;
  object-fit: cover;
  max-width: 1400px;
  right: 0;

  @media(min-width: ${breakpoints.xxs}){
    height: 350px;
  }

  @media(min-width: ${breakpoints.md}){
    bottom: -50px;
  }
  
  @media(min-width: ${breakpoints.lg}){
    bottom: 50px;
  }

  @media(min-width: 1600px){
    height: 400px;
  }

  @media(min-width: 2000px){
    height: 500px;
  }
`;

const ContactBlock = ({ header, title, content, link, backgrounds }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const {isLight} = useContext(ThemeContext);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 768 && isDesktop === false) {
        setIsDesktop(true)
      } else if (window.innerWidth <= 768 && isDesktop === true) {
        setIsDesktop(false)
      }
    }
    if (isBrowser()) {
      onResize();
      window.addEventListener("resize", onResize)
    }
  }, [isDesktop])

  return (
    <ContactContainer>
      <ContentContainer>
        {header &&
          <Title position={header.position}>{header.header}</Title>
        }
        <Row>
          <Col md={6} lg={5}>
            <Header>{documentToReactComponents(JSON.parse(title))}</Header>
            <Content>{documentToReactComponents(JSON.parse(content))}</Content>
            {link &&
              <Button to={link.ctaUrl} isred={true} isTrue={true} large={1}>
                {link.ctaLabel}
              </Button>
            }
          </Col>
          <Col md={5} mdOffset={1} lg={4} lgOffset={2}>
            <ContactForm/>
          </Col>
        </Row>
      </ContentContainer>
      <Background src={isLight ? (isDesktop ? backgrounds.backgroundDesktopLight.file.url : backgrounds.backgroundMobileLight.file.url) : (isDesktop ? backgrounds.backgroundDesktopDark.file.url : backgrounds.backgroundDesktopDark.file.url)} alt="Background" light={isLight ? 1 : 0}/>
    </ContactContainer>
  )
}

export default ContactBlock;
